import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Button } from 'reactstrap';
import img1 from "../Images/blogimage.svg";
import img2 from "../Images/blogimage2.svg";
import img3 from "../Images/blogimage2.svg";
import img4 from "../Images/blogimage3.svg";
import img5 from "../Images/blogimage4.svg";
import img6 from "../Images/blogimage5.svg";
import img7 from "../Images/blogbanner.svg";

// Sample blog data
const blogPosts = [
  {
    id: 1,
    image: img1,
    date: "17th June 2024",
    title: "The Side Hustle Shuffle: Making Time for Your Passion Project with Ease",
    preview: "We've all been there. You scroll through social media and see everyone..."
  },
  {
    id: 2,
    image: img2,
    date: "23rd July 2024",
    title: "Mastering the Art of Self-Taught Programming: A Beginner's Guide",
    preview: "In the digital age, programming skills are more valuable than ever..."
  },
  {
    id: 3,
    image: img4,
    date: "5th August 2024",
    title: "From Novice to Expert: Charting Your Course in Self-Directed Learning",
    preview: "The journey of self-directed learning can be both exciting and daunting..."
  },
  {
    id: 4,
    image: img5,
    date: "19th September 2024",
    title: "The Power of Community in Online Learning: Building Your Support Network",
    preview: "While self-learning offers flexibility and autonomy, it doesn't mean you have to go it alone..."
  },
  {
    id: 5,
    image: img5,
    date: "2nd October 2024",
    title: "Balancing Act: Juggling Work, Life, and Continuous Learning",
    preview: "In today's fast-paced world, the pursuit of knowledge never stops..."
  },
  {
    id: 6,
    image: img7,
    date: "15th November 2024",
    title: "Tech Tools for Self-Learners: Maximizing Efficiency in Your Studies",
    preview: "The digital revolution has brought a wealth of tools to aid self-learners..."
  },
  {
    id: 7,
    image: img7,
    date: "7th December 2024",
    title: "The Psychology of Self-Learning: Understanding Your Mind for Better Results",
    preview: "Delving into the realm of self-learning isn't just about acquiring knowledge..."
  },
  {
    id: 8,
    image: img1,
    date: "20th January 2025",
    title: "Beyond Books: Unconventional Resources for the Modern Self-Learner",
    preview: "While traditional learning resources have their place, the modern self-learner has a world of options..."
  },
  {
    id: 9,
    image: img1,
    date: "3rd February 2025",
    title: "From Theory to Practice: Applying Self-Taught Skills in the Real World",
    preview: "One of the biggest challenges for self-learners is bridging the gap between theory and practice..."
  },
  {
    id: 10,
    image: img1,
    date: "18th March 2025",
    title: "The Future of Self-Learning: Trends and Predictions for the Next Decade",
    preview: "As technology continues to evolve at a rapid pace, so too does the landscape of self-learning..."
  }
];

const Blog = () => {
  return (
    <Container fluid className="py-5" style={{ background: '#f8f9fa' }}>
      <Container>
        <Row className="justify-content-center mb-5">
          <Col md={8} className="text-center">
            <h1 className="fw-bold mb-3">Blog</h1>
            <p className="text-muted">Empower Your Self-Learning Journey with a Wealth of Resources and Insights</p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {blogPosts.map((post) => (
            <Col key={post.id} md={6} className="mb-4">
              <Card className="h-100 shadow-sm" style={{ borderRadius: '20px', overflow: 'hidden' }}>
                <Card.Img variant="top" src={post.image} style={{ borderRadius: '20px 20px 0 0', height: '200px', objectFit: 'cover' }} />
                <Card.Body className="d-flex flex-column">
                  <small className="text-muted mb-2">{post.date}</small>
                  <Card.Title className="fw-bold mb-3">{post.title}</Card.Title>
                  <Card.Text className="text-muted">{post.preview}</Card.Text>
                  {/* <Button color="primary" outline className="mt-auto align-self-start" style={{ borderRadius: '50px' }}>Read More</Button> */}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
};

export default Blog;