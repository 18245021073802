// Components/About.js
import React from 'react';

const About = () => {
  return (
    <div>
      <h1>About Us</h1>
      {/* Add your about page content here */}
    </div>
  );
};
export default About;