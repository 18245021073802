import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import MyNav from './Components/Nav';
import Home from './Components/Home';
import Services from './Components/Services';
import Portfolio from './Components/Portfolio';
import FAQ from './Components/FAQ';
import Footer from './Components/Footer';
import Contact from './Components/contact';
import About from './Components/About'; // You'll need to create this
import Blog from './Components/Blog'; // You'll need to create this
import 'bootstrap/dist/css/bootstrap.min.css';

const MainContent = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  return (
    <>
      {isHomePage && (
        <>
          <Home />
          <Services />
          <Portfolio />
          <FAQ />
          <Contact />
        </>
      )}
      <Routes>
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/services" element={<Services />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </>
  );
};

function App() {
  return (
    <Router>
      <React.Fragment>
        <MyNav />
        <MainContent />
        <Footer />
      </React.Fragment>
    </Router>
  );
}

export default App;