import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Button } from "reactstrap";

const MyNav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const gradientBackgroundStyle = {
    background: "linear-gradient(70deg,  #FCFCFC 10%, #FCFCFC 50%)",
  };

  const navLinkStyle = {
    marginLeft: '15px',
    marginRight: '15px',
    color: '#000',
  };

  const buttonStyle = {
    backgroundColor: "#FF7223",
    borderColor: "#FF7223",
  };

  const centerNavStyle = {
    display: "flex",
    justifyContent: "center",
    flexGrow: 1,
  };

  const toggleButtonStyle = {
    border: "1px solid #121212",
    backgroundColor: "transparent",
  };

  const toggleIconStyle = {
    backgroundColor: 'transparent',
  };

  const handleNavClick = (path) => {
    navigate(path);
    setIsOpen(false);
  };

  return (
    <div className="justify-center">
      <Navbar
        bg="myRed"
        data-bs-theme="light"
        style={gradientBackgroundStyle}
        expand="lg"
      >
        <Container>
          <Navbar.Brand className="fw-bold" as={Link} to="/" style={{ color: '#FF7223' }}>
            CurateLearn
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            style={toggleButtonStyle}
            onClick={() => setIsOpen(!isOpen)}
          >
            <span
              className="navbar-toggler-icon"
              style={toggleIconStyle}
            ></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav" in={isOpen}>
            <Nav style={centerNavStyle}>
              <Nav.Link as={Link} to="/" style={navLinkStyle} onClick={() => handleNavClick('/')}>Overview</Nav.Link>
              <Nav.Link as={Link} to="/" style={navLinkStyle} onClick={() => handleNavClick('/')}>About Us</Nav.Link>
              <Nav.Link as={Link} to="/blog" style={navLinkStyle} onClick={() => handleNavClick('/blog')}>Blog</Nav.Link>
              <Nav.Link as={Link} to="/faq" style={navLinkStyle} onClick={() => handleNavClick('/faq')}>FAQ's</Nav.Link>
            </Nav>
            <Button
              color="primary"
              style={buttonStyle}
              onClick={() =>
                (window.location.href = "https://app.curatelearn.com/")
              }
            >
              {" "}
              Get Started
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default MyNav;